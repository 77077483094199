import { ApiAction } from '../api-actions';
import { ApiModule } from '../api-modules/api-modules';
import { linkModuleAndAction } from '../link-module-and-action';

export const SettingsPermission = {
	READ_ADVERTISEMENTS_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_ADVERTISEMENTS, ApiAction.READ),
	READ_ARCHIVED_PROJECTS_PERMISSION: linkModuleAndAction(ApiModule.PROJECTS, ApiAction.READ_ARCHIVES),
	READ_COMPANIES_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_COMPANIES, ApiAction.READ),
	READ_PRINT_JOB_ACTIVITY_REPORT_PERMISSION: linkModuleAndAction(ApiModule.REPORTS, ApiAction.PRINT_JOB_ACTIVITY),
	READ_PRINT_JOBS_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_PRINT_JOBS, ApiAction.READ),
	READ_PROFILE_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_PROFILE, ApiAction.READ),
	READ_REPORTS_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_REPORTS, ApiAction.READ),
	READ_TENANTS_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_TENANTS, ApiAction.READ),
	READ_THEMES_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_THEMES, ApiAction.READ),
	READ_USER_ROLES_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_ROLES, ApiAction.READ),
	READ_USERS_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_USERS, ApiAction.READ),
	UPDATE_PROFILE_PERMISSION: linkModuleAndAction(ApiModule.SETTINGS_ACCESS, ApiAction.READ),
} as const;
