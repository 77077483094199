import { Injectable, signal } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

@Injectable({
	providedIn: 'any',
})
export class DarkModeService {
	darkMode = signal(false);

	constructor() {
		Preferences.get({ key: 'darkMode' }).then((value) => {
			this.darkMode.set(value.value === 'true');
		});
	}

	setDarkMode(value: boolean): void {
		Preferences.set({ key: 'darkMode', value: value.toString() }).then(() => this.darkMode.set(value));
	}
}
